import React from 'react'
import styled from 'styled-components'
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';

export default function UploadTestAlbumItem({data,album,onClick}:any) {
    return (
        <Wrap $active={data?.album_id === undefined ? false : data?.album_id === album?.album_id} onClick={onClick}>
            <LeftWrap>
                <ImgWrap>
                    <img alt='album-img' src={data?.artwork}></img>
                </ImgWrap>
                <BasicText>{data?.album_name}</BasicText>
            </LeftWrap>
            <RightWrap>{data?.approve_sound_count} 곡</RightWrap>
        </Wrap>
    )
}

const Wrap = styled(RowBetween)<{$active?:boolean}>`
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 10px;
    border-color: ${({$active}) => $active ? addTheme.color : '#fff'};
`;
const LeftWrap = styled(Row)`
    gap: 10px;
`;
const ImgWrap = styled.div`
    width: 56px;
    height: 56px;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    &>img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 110%;
    }
`;
const RightWrap = styled.div`
    flex-shrink: 0;
`;