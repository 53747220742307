import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Center } from '../../../style/basic/commonStyle';

export default function DropImgItem({setImg}:any) {
    const [fileBase64, setFileBase64] = useState<any>('');

    const onDrop = useCallback((files: any) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async () => {
                const base64Result = reader.result;
                setFileBase64(base64Result);
                setImg(base64Result);
            };
            reader.readAsDataURL(file);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
        },
    });

    return (
        <div {...getRootProps()} style={{ border: '1px solid #fff', padding: '10px', marginTop: '5px', borderRadius: '4px' }}>
            <input {...getInputProps()} />
            <Center style={{ marginTop: '10px' }}>
                <ImgWrap>
                    {fileBase64 !== '' ? (
                        <img alt='album-img' src={fileBase64}></img>
                    ) : (
                        <Center style={{ width: '100%' }}>이미지를 선택하세요.</Center>
                    )}
                </ImgWrap>
            </Center>
        </div>
    )
}

const ImgWrap = styled(Center)`
    position: relative;
    overflow: hidden;
    color: #fff;
    width: 160px;
    overflow: hidden;
    &::after{
        content:'';
        padding-bottom: 100%;
        display: block;
    }
    & img{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        height: 100%;
    }
`;
