import { axiosConfigInstance } from "./config";

// 음악 업로드
export const apiSetSoundUpload = async(token:string,user_id:any,album_id:any,sound_title:string,sound_genre:string,sound_bpm:any,sound_mood:string,file:any,file_path:string) =>{
    try {
        const res = await axiosConfigInstance(`/sound/submit`,token).post('',{
            method: 'uploadSoundFileFromBase64',
            params: {
                user_id,
                album_id,
                sound_title,
                sound_genre,
                sound_small_category:'3',
                sound_bpm,
                sound_mood,
                audio_base64: file,
                audio_path: file_path,
            }
        });
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 앨범 생성
export const apiSetAlbum = async(token:string,user_id:any,album_name:string,image_base64:string) =>{
    try {
        const res = await axiosConfigInstance('/sound/album',token).post('',{
            method: 'createNewAlbumFromBaseImage',
            params: {
                user_id,
                album_name,
                image_base64,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 음원 장르 리스트
export const apiGetMusicGenreList = async(token:string,user_id:any,) =>{
    try {
        const res = await axiosConfigInstance('/sound',token).post('',{
            method: 'getSoundCategory',
            params: {
                user_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 앨범 리스트
// filter_type [선택] (1: 활성화 된 앨범 리스트 / 0: 숨김 처리 된 앨범 리스트 / all: 전체 앨범)
export const apiGetUserAlbumList = async(token:string,user_id:any,target_user_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/sound/album',token).post('',{
            method: 'getAlbumList',
            params: {
                user_id,
                target_user_id,
                page,
                count,
                filter_type: '1',
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}
