import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import BasicText from '../../basic/BasicText';
import { Center, Row } from '../../../style/basic/commonStyle';
import DropImgItem from '../../common/item/DropImgItem';
import { IoClose } from "react-icons/io5";
import { apiSetAlbum } from '../../../api/api_upTest';

const ALBUM_INIT = {
    album_name: '',
    image_base64: '',
}

export default function OpenUploadTextAlbumPage({ user, open, setOpen, setReload }: any) {
    const ref = useRef<any>(null);
    const [album,setAlbum] = useState(ALBUM_INIT);

    useEffect(()=>{
        if(!open){
            setAlbum(ALBUM_INIT);
        }
    },[open])

    const close = () => {
        setOpen(false);
    }

    const valueChange = (name:string,val:any) =>{
        setAlbum((obj:any)=>({
            ...obj,
            [name]: val,
        }))
    }

    const createAction = async() =>{
        if(album.album_name === ''){
            alert('앨범 제목을 입력해주세요.');
            return;
        }
        if(album.image_base64 === ''){
            alert('앨범 이미지를 선택해주세요.');
            return;
        }
        
        const res = await apiSetAlbum(user?.jwt_token,user?.user_id,album.album_name,album.image_base64);
        if(res.result){
            alert('앨범이 생성되었습니다.');
            setReload((bol:any)=>!bol);
            close();
        }else{
            alert(res.error);
        }
    }

    return (
        <Dialog
            ref={ref}
            open={open}
            onClose={close}
            sx={{
                // '.MuiDialog-container':{alignItems:mediaQuery ? 'end' :'center',},
                '.MuiPaper-root': { margin: '8px', maxWidth: '460px', width: '100%', borderRadius: '6px', backgroundColor: '#3e3e3e' }
            }}
        >
            <DialogTitle sx={{position:'relative'}}>
                <BasicText color='#fff' align='center' fontWeight={700} fontSize={20}>앨범 생성</BasicText>
                <CloseWrap>
                    <IoClose color='#fff' size={22} onClick={close}/>
                </CloseWrap>
            </DialogTitle>
            <DialogContent dividers={true}>
                <Wrap>
                    <InnerWrap>
                        <ItemWrap>
                            <BasicText color='#fff'>앨범 이름</BasicText>
                            <input value={album.album_name} onChange={(e:any)=>valueChange('album_name',e.target.value)}></input>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText color='#fff'>앨범 이미지</BasicText>
                            <DropImgItem setImg={(val:any)=>valueChange('image_base64',val)}/>
                        </ItemWrap>
                    </InnerWrap>
                    <BtnWrap>
                        <BtnItem onClick={createAction}>앨범 생성</BtnItem>
                    </BtnWrap>
                </Wrap>
            </DialogContent>
        </Dialog>
    )
}

const Wrap = styled.div``;
const CloseWrap = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    & svg{
        cursor: pointer;
    }
`;
const InnerWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
    gap: 16px;
`;
const ItemWrap = styled.div`
    width: 100%;
    &>input{
        margin-top: 5px;
        border: 1px solid #fff;
        border-radius: 4px;
        padding: 10px;
        color: #fff;
        width: 100%;
    }
    &>input[type='file']{
        padding: 10px;
        border: none;
    }
`;

const BtnWrap = styled(Center)`
    margin-top: 18px;
`;
const BtnItem = styled(Center)`
    border: 1px solid #fff;
    border-radius: 4px;
    width: 120px;
    height: 40px;
    cursor: pointer;
    color: #fff;
`;