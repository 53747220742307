import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useUserInfo } from '../utils/custom-hooks/common/useUserInfo';
import SearchPage from '../components/search/page/SearchPage';
import MusicPage from '../components/music/page/MusicPage'
import SearchDetailPage from '../components/search/page/SearchDetailPage';
import ScrollToTop from '../utils/custom-hooks/common/ScrollToTop';
import SearchPlayListDetailPage from '../components/search/page/SearchPlayListDetailPage';
import Player from '../components/player/page/Player';
import LoginPage from '../components/login/page/LoginPage';
import UploadPage from '../components/upload/page/UploadPage';
import MyPage from '../components/mypage/page/MyPage';
import MyPageMusicPage from '../components/mypage/page/MyPageMusicPage';
import MyPageCheckPage from '../components/mypage/page/MyPageCheckPage';
import MyPageInvitePage from '../components/mypage/page/MyPageInvitePage';
import MyPageGetPointPage from '../components/mypage/page/MyPageGetPointPage';
import MyPagePointListPage from '../components/mypage/page/MyPagePointListPage';
import { useEffect } from 'react';
import { apiLogin, apiSetLog } from '../api/api_login';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../store/common/user';
import IntroPage from '../components/Intro/page/IntroPage';
import NoticePage from '../components/notice/page/NoticePage';
import TermsPage from '../components/terms/page/TermsPage';
import NoticeDetailPage from '../components/notice/page/NoticeDetailPage';
import UserPage from '../components/user/page/UserPage';
import StoryPage from '../components/story/page/StoryPage';
import WelcomePage from '../components/welcome/page/WelcomePage';
import { Helmet } from 'react-helmet-async';
import SearchAlbumDetailPage from '../components/search/page/SearchAlbumDetailPage';
import UploadTextPage from '../components/uploadTest/page/UploadTextPage';

export default function Router(){
    const dispatch = useDispatch();
    const user = useUserInfo();

    useEffect(()=>{
        if(user.user_id === 0){
            login();
        }else{
            apiSetLog(user.jwt_token,user.user_id,user.user_country);
        }
    },[user.user_id])

    const login = async() =>{
        const res = await apiLogin();
        if(res.result){
            dispatch(setUserInfo({...res.data,user_country:res.user_country}));
        }
    }
    
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ScrollToTop></ScrollToTop>
            <Helmet>
                <title>SUBOX MUSIC</title>
                <meta property="og:title" content={'SUBOX MUSIC'} />
            </Helmet>
            <Routes>
                <Route path='/' element={<IntroPage/>} />
                {/* <Route path='/login' element={<LoginPage/>} /> */}
                {user.jwt_token !== '' &&
                    <>
                        <Route path='/music' element={<MusicPage/>} />
                        <Route path='/search' element={<SearchPage/>} />
                        <Route path='/searchDetail/:id' element={<SearchDetailPage/>} />
                        <Route path='/searchPlayListDetail/:id' element={<SearchPlayListDetailPage/>} />
                        <Route path='/searchAlbumDetail/:id' element={<SearchAlbumDetailPage/>} />
                        <Route path='/userPage/:id' element={<UserPage/>} />
                        <Route path='/upTest' element={<UploadTextPage/>} />
                        {/* <Route path='/myPage' element={<MyPage/>} />
                        <Route path='/myPageMusic' element={<MyPageMusicPage/>} />
                        <Route path='/myPageCheck' element={<MyPageCheckPage/>} />
                        <Route path='/myPageInvite' element={<MyPageInvitePage/>} />
                        <Route path='/myPageGetPoint' element={<MyPageGetPointPage/>} />
                        <Route path='/myPagePointList' element={<MyPagePointListPage/>} />
                        <Route path='/story' element={<StoryPage />} /> */}
                    </>
                }
                <Route path='/notice/:page' element={<NoticePage />} />
                <Route path='/noticeDetail/:id' element={<NoticeDetailPage />} />
                <Route path='/terms/:id' element={<TermsPage />} />
                <Route path='/welcome' element={<WelcomePage />} />
                <Route path='*' element={<IntroPage />} />
            </Routes>
            <Player></Player>
        </BrowserRouter>
    )
}