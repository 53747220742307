import React, { useState } from 'react'
import styled from 'styled-components'
import { Center } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

export default function UploadTestPassPart({setLoginChk}:any) {
    const [pass,setPass] = useState('');
    const submitAction = (e:any)=>{
        e.preventDefault();
        if(pass === 'upTest250815!@'){
            sessionStorage.setItem('su_up_test','true');
            setLoginChk(true);
        }else{
            alert('비밀번호가 틀렸습니다.');
        }
    }
    return (
        <Wrap>
            <PassWrap onSubmit={submitAction}>
                <BasicText fontSize={20} align='center'>비밀번호</BasicText>
                <InputWrap>
                    <input value={pass} onChange={(e:any)=>setPass(e.target.value)} type='password' placeholder='비밀번호 입력'></input>
                </InputWrap>
            </PassWrap>
        </Wrap>
    )
}

const Wrap = styled(Center)`
    width: 100%;
    height: 100vh;
`;

const PassWrap = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
const InputWrap = styled.div`
    margin-top: 8px;
    &>input{
        border: 1px solid #fff;
        padding: 10px;
        border-radius: 4px;
        color: #fff;
    }
`;