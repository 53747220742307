import React,{useEffect, useRef, useState} from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components';
import Lottie from 'lottie-web';
import loadingJson from '../../../data/loading.json';
import { Center, TextItem } from '../../../style/basic/commonStyle';
import ReactGA from 'react-ga4';

export default function ScrollToTop() {
  const {pathname} = useLocation();
  const ani = useRef<any>(null);
  const [loading,setLoading] = useState(false);
  const [analytics,setAnalytics] = useState<any>(null);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      // music.subox
      ReactGA.initialize('G-1ED18YR5PP');
      // subox.co
      // ReactGA.initialize('G-EE0WLCLCJZ');
      setAnalytics(true);
    }
  }, []);

  useEffect(() => {
    if(analytics === null) return;
    ReactGA.set({ page: pathname });
    ReactGA.send("pageview");
  }, [analytics,pathname]);
  
  useEffect(() => {
    window.scrollTo(0,0);
    if(pathname === '/welcome' || pathname === '/' || pathname === '/upTest' || pathname === '/uptest') return;
    if(pathname.includes("notice")) return;
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () =>{clearTimeout(timer)}
  }, [pathname]);

  useEffect(()=>{
    if(loading){
      if(ani.current){
        Lottie.loadAnimation({
          container: ani.current,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: loadingJson,
        });
        return () => Lottie.destroy();
      }
    }
  },[ani,loading])
  
  return (
    <Wrap stateShow={loading}>
      <LoadingWrap ref={ani}></LoadingWrap>
      <TextItem marginTop={15} fontSize={20} textAlign='center'>Loading...</TextItem>
    </Wrap>
  );
}

const Wrap = styled(Center)<{stateShow:boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,1);
  z-index: 99999;
  display: ${props=> props.stateShow ? 'flex':'none'};
  flex-direction: column;
`;

const LoadingWrap = styled.div`
  width: 100%;
  height: 100%;
  max-width: 270px;
  height: 270px;
`;