import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import UploadTestPassPart from '../part/UploadTestPassPart';
import UploadTestSelectWrapPart from '../part/UploadTestSelectWrapPart';

export default function UploadTextPage() {
    const [loginChk,setLoginChk] = useState(false);

    useEffect(()=>{
        const chk = sessionStorage.getItem('su_up_test');
        if(chk === 'true'){
            setLoginChk(true);
        }
    },[])

    return (
        <Wrap>
            {loginChk ?
                <UploadTestSelectWrapPart /> :
                <UploadTestPassPart setLoginChk={setLoginChk} />
            }
        </Wrap>
    )
}

const Wrap = styled.div``;