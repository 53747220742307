import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center, Row } from '../../../style/basic/commonStyle';
import SelectItem from '../../common/item/SelectItem';
import { apiGetMusicGenreList } from '../../../api/api_upTest';
import { MenuItem } from '@mui/material';
import { addTheme } from '../../../style/addTheme';
import { fileTypeRes } from '../../../utils/format/string';

export default function UploadTestMusicPart({user,info,valueChange}:any) {
    const [genreList,setGenreList] = useState([]);

    useEffect(()=>{
        if(user?.user_id === undefined || user?.user_id === 0) return;
        getGenreList();
    },[user?.user_id])

    // 장르리스트
    const getGenreList = async() =>{
        const res = await apiGetMusicGenreList(user.jwt_token,user.user_id);
        if(res.result){
            setGenreList(res.data);
        }
    }

    // 파일 선택
    const fileSelect = async(event:any) =>{
        const file = event.target.files[0];
        const fileType = fileTypeRes(event.target.value);
        if(file){
            if(fileType === 'mp3' || fileType === 'wav'){
                const reader = new FileReader();
                reader.onload = async() => {
                    const base64Result = reader.result; 
                    valueChange('audio_base64',base64Result);
                };
                reader.readAsDataURL(file);
            }else{
                alert('mp3 또는 wav확장자를 선택해주세요.');
            }
        }
    }

    return (
        <Wrap>
            <BasicText fontSize={18} align='center'>음악 선택</BasicText>
            <InnerWrap>
                <ItemWrap>
                    <BasicText>음악파일</BasicText>
                    <input type='file' accept='audio/mp3,audio/wav' onChange={fileSelect}></input>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>노래 제목</BasicText>
                    <input value={info.sound_title} onChange={(e:any)=>valueChange('sound_title',e.target.value)}></input>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>설명</BasicText>
                    <input value={info.sound_mood} onChange={(e:any)=>valueChange('sound_mood',e.target.value)}></input>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>장르</BasicText>
                    {genreList.length === 0 ?
                        <></> :
                        <SelectItem value={info.sound_genre} setValue={(val:any)=>valueChange('sound_genre',val)} margin={'5px 0 0 0'} none='no'>
                            <MenuItem value='' disabled>장르를 선택해주세요</MenuItem>
                            {genreList.map((data:any,index:any)=>{
                                return(
                                    <MenuItem key={`genre-${index}`} value={data.category_title}>{data.category_title}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    }
                </ItemWrap>
                <ItemWrap>
                    <BasicText>BPM</BasicText>
                    <BpmWrap>
                        <BpmItem $active={info.sound_bpm === 40} onClick={()=>valueChange('sound_bpm',40)}>
                            <BasicText color={'inherit'}>느림</BasicText>
                            <BasicText fontSize={15} color={'inherit'}>0~40</BasicText>
                        </BpmItem>
                        <BpmItem $active={info.sound_bpm === 80} onClick={()=>valueChange('sound_bpm',80)}>
                            <BasicText color={'inherit'}>중간</BasicText>
                            <BasicText fontSize={15} color={'inherit'}>40~80</BasicText>
                        </BpmItem>
                        <BpmItem $active={info.sound_bpm === 120} onClick={()=>valueChange('sound_bpm',120)}>
                            <BasicText color={'inherit'}>빠름</BasicText>
                            <BasicText fontSize={15} color={'inherit'}>120~</BasicText>
                        </BpmItem>
                    </BpmWrap>
                </ItemWrap>
            </InnerWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
`;
const InnerWrap = styled(Row)`
    margin-top: 10px;
    flex-direction: column;
    gap: 16px;
    align-items: start;
`;

const ItemWrap = styled.div`
    width: 100%;
    &>input{
        margin-top: 5px;
        border: 1px solid #fff;
        border-radius: 4px;
        padding: 10px;
        color: #fff;
        width: 100%;
    }
    &>input[type='file']{
        padding: 10px;
        border: none;
    }
`;

const BpmWrap = styled(Row)`
    gap: 20px;
    margin-top: 5px;
`;
const BpmItem = styled(Center)<{$active?:boolean}>`
    width: calc(33.33% - 13.33px);
    padding: 8px 12px;
    flex-direction: column;
    background-color: ${({$active}) => $active ? addTheme.color : '#3e3e3e'};
    color: ${({$active}) => $active ? '#000' : '#fff'};
    border-radius: 4px;
    cursor: pointer;
`;