export const USER_LIST = [
    {user_id: 42788, user_nickname: 'W.A.V.E', user_timestamp: 1739257169000, jwt_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiSldUIiwidXNlcl9pZCI6NDI3ODgsInVzZXJfdGltZXN0YW1wIjoiMTczOTI1NzE2OTAwMCIsImlhdCI6MTczOTI1NzM1OSwiZXhwIjoxNzcwNzkzMzU5LCJpc3MiOiJzdWJveC1zb3VuZC12MyJ9.Fnr4i0lfH39ZCoq4gl_vXiMiFTYhgdKekVrCxD8bRWg'},
    {user_id: 42799, user_nickname: '비트MAX', user_timestamp: 1739257169000, jwt_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiSldUIiwidXNlcl9pZCI6NDI3OTksInVzZXJfdGltZXN0YW1wIjoiMTczOTI1NzE2OTAwMCIsImlhdCI6MTczOTI1NzY0NiwiZXhwIjoxNzcwNzkzNjQ2LCJpc3MiOiJzdWJveC1zb3VuZC12MyJ9.yZbEft3ESD0MKSkxGgD18uZ0MDJRc68bOemsYZeICCI'},
    {user_id: 42790, user_nickname: '베티', user_timestamp: 1739257169000, jwt_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiSldUIiwidXNlcl9pZCI6NDI3OTAsInVzZXJfdGltZXN0YW1wIjoiMTczOTI1NzE2OTAwMCIsImlhdCI6MTczOTI1NzQ0MywiZXhwIjoxNzcwNzkzNDQzLCJpc3MiOiJzdWJveC1zb3VuZC12MyJ9.InN40dzioA7QYGlkWUUW6mjO_ftp1rjrHYnNgfjBE9E'},
    {user_id: 42791, user_nickname: 'BE.', user_timestamp: 1739257169000, jwt_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiSldUIiwidXNlcl9pZCI6NDI3OTEsInVzZXJfdGltZXN0YW1wIjoiMTczOTI1NzE2OTAwMCIsImlhdCI6MTczOTI1NzQ2NSwiZXhwIjoxNzcwNzkzNDY1LCJpc3MiOiJzdWJveC1zb3VuZC12MyJ9.iygvldH_UwK0mD5v0vDsfiFmMh2eIrjXIrT3vJiL7AQ'},
    {user_id: 42792, user_nickname: 'ImAZ', user_timestamp: 1739257169000, jwt_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiSldUIiwidXNlcl9pZCI6NDI3OTIsInVzZXJfdGltZXN0YW1wIjoiMTczOTI1NzE2OTAwMCIsImlhdCI6MTczOTI1NzQ4NCwiZXhwIjoxNzcwNzkzNDg0LCJpc3MiOiJzdWJveC1zb3VuZC12MyJ9.AzWBP3n3KzJ8ErWDf81u-GRpacLaF6yNNgFz5r7lD2Q'},
    {user_id: 42793, user_nickname: '아임', user_timestamp: 1739257169000, jwt_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiSldUIiwidXNlcl9pZCI6NDI3OTMsInVzZXJfdGltZXN0YW1wIjoiMTczOTI1NzE2OTAwMCIsImlhdCI6MTczOTI1NzQ5NiwiZXhwIjoxNzcwNzkzNDk2LCJpc3MiOiJzdWJveC1zb3VuZC12MyJ9.7HYC_kwtcFWCBpu7HXmH0G0fWnAItH86qsqGYw9yUeE'},
    {user_id: 42794, user_nickname: '스쿺', user_timestamp: 1739257169000, jwt_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiSldUIiwidXNlcl9pZCI6NDI3OTQsInVzZXJfdGltZXN0YW1wIjoiMTczOTI1NzE2OTAwMCIsImlhdCI6MTczOTI1NzUxMCwiZXhwIjoxNzcwNzkzNTEwLCJpc3MiOiJzdWJveC1zb3VuZC12MyJ9.gSc3rXs2zU2jiY60cY8Gh5haXM0vgNFTc-PHLEtDNbs'},
    {user_id: 42795, user_nickname: 'MeZZZ', user_timestamp: 1739257169000, jwt_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiSldUIiwidXNlcl9pZCI6NDI3OTUsInVzZXJfdGltZXN0YW1wIjoiMTczOTI1NzE2OTAwMCIsImlhdCI6MTczOTI1NzUyNCwiZXhwIjoxNzcwNzkzNTI0LCJpc3MiOiJzdWJveC1zb3VuZC12MyJ9.x1Gi5_iyeMQsh-jSqUs8S1Ar1nFFQhCQaw2itKluiZ8'},
    {user_id: 42796, user_nickname: '하치_Hachi', user_timestamp: 1739257169000, jwt_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiSldUIiwidXNlcl9pZCI6NDI3OTYsInVzZXJfdGltZXN0YW1wIjoiMTczOTI1NzE2OTAwMCIsImlhdCI6MTczOTI1NzUzOCwiZXhwIjoxNzcwNzkzNTM4LCJpc3MiOiJzdWJveC1zb3VuZC12MyJ9.7pHR531PUmLO36fuLQu7CaJooj2sogQ7GJ5GRAX9nfk'},
    {user_id: 42797, user_nickname: 'LEOa', user_timestamp: 1739257169000, jwt_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiSldUIiwidXNlcl9pZCI6NDI3OTcsInVzZXJfdGltZXN0YW1wIjoiMTczOTI1NzE2OTAwMCIsImlhdCI6MTczOTI1NzU1MSwiZXhwIjoxNzcwNzkzNTUxLCJpc3MiOiJzdWJveC1zb3VuZC12MyJ9.hHFtBrkJxiMj2up2cm0bV7ly_svjhQVthDVJ0tzYSnU'},
]