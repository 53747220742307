import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Row } from '../../../style/basic/commonStyle';
import UploadTestUserItem from '../item/UploadTestUserItem';
import { USER_LIST } from '../data/user';

export default function UploadTestUserPart({ user, setUser,setAlbum }: any) {
    const userClick = (data:any) =>{
        setUser(data);
        setAlbum(undefined);
    }
    return (
        <Wrap>
            <BasicText fontSize={18} align='center'>유저 선택</BasicText>
            <InnerWrap>
                {USER_LIST.map((data: any, index: any) => {
                    return (
                        <UploadTestUserItem key={`test-user-${index}`} data={data} user={user} onClick={() =>userClick(data)} />
                    )
                })}
            </InnerWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled(Row)`
    margin-top: 10px;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    height: 460px;
    overflow-y: auto;
`;