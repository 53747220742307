import React, { useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import UploadTestUserPart from './UploadTestUserPart';
import UploadTestAlbumPart from './UploadTestAlbumPart';
import UploadTestMusicPart from './UploadTestMusicPart';
import { apiSetSoundUpload } from '../../../api/api_upTest';
import { addTheme } from '../../../style/addTheme';

export default function UploadTestSelectWrapPart() {
    const [user,setUser] = useState<any>();
    const [album,setAlbum] = useState<any>();
    const [music, setMusic] = useState({
        sound_title: '',
        sound_genre: '',
        sound_bpm: 40,
        sound_mood: '',
        audio_base64: '',
    })

    const musicChange = (name:string,val:any) =>{
        setMusic((obj:any)=>({
            ...obj,
            [name]: val,
        }))
    }

    const uploadAction = async() =>{
        if(user?.user_id === undefined || user?.user_id === 0){
            alert('유저를 선택해주세요.');
            return;
        }
        if(album?.album_id === undefined || album?.album_id === 0){
            alert('앨범을 선택해주세요.');
            return;
        }
        if(music.audio_base64 === ''){
            alert('음악파일을 선택해주세요.');
            return;
        }
        if(music.sound_title === ''){
            alert('제목을 입력해주세요.');
            return;
        }
        if(music.sound_genre === ''){
            alert('장르를 선택해주세요.');
            return;
        }
        const res = await apiSetSoundUpload(user.jwt_token,user.user_id,album.album_id,music.sound_title,music.sound_genre,music.sound_bpm,music.sound_mood, music.audio_base64, '');
        if(res.result){
            alert('업로드가 되었습니다.');
            window.location.reload();
        }else{
            alert(res.error);
        }
    }
    
    return (
        <Wrap>
            <BasicText marginTop={5} fontSize={28} align='center'>업로드</BasicText>
            <MaxWidthWrap maxWidth='1280px'>
                <InnerWrap>
                    <ContentWrap>
                        <UploadTestUserPart user={user} setUser={setUser} setAlbum={setAlbum}/>
                    </ContentWrap>
                    <ContentWrap>
                        <UploadTestAlbumPart user={user} album={album} setAlbum={setAlbum}/>
                    </ContentWrap>
                    <ContentWrap>
                        <UploadTestMusicPart user={user} info={music} valueChange={musicChange} />
                        <BtnWrap>
                            <BtnItem onClick={uploadAction}>음악 업로드</BtnItem>
                        </BtnWrap>
                    </ContentWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled(Row)`
    margin-top: 30px;
    gap: 20px;
    align-items: start;
    flex-wrap: wrap;
`;
const ContentWrap = styled.div`
    width: calc(33.33% - 13.33px);
    padding: 10px;
    @media ${addTheme.media.m}{
        width: 100%;
    }
`;

const BtnWrap = styled(Center)`
    margin-top: 30px;
`;

const BtnItem = styled(Center)`
    border: 1px solid #fff;
    border-radius: 4px;
    width: 160px;
    height: 42px;
    cursor: pointer;
    font-weight: 700;
`;