import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { apiGetUserAlbumList } from '../../../api/api_upTest';
import { Center, Row } from '../../../style/basic/commonStyle';
import UploadTestAlbumItem from '../item/UploadTestAlbumItem';
import OpenUploadTextAlbumPage from '../page/OpenUploadTextAlbumPage';

export default function UploadTestAlbumPart({user,album,setAlbum}:any) {
    const [open,setOpen] = useState(false);
    const [reload,setReload] = useState(false);
    const [list,setList] = useState([]);

    useEffect(()=>{
        if(user?.user_id === undefined || user?.user_id === 0) return;
        getAlbum();
    },[user?.user_id,reload]);

    const getAlbum = async() =>{
        const res = await apiGetUserAlbumList(user?.jwt_token,user?.user_id,user.user_id,1,150);
        if(res.result){
            setList(res.data);
        }
    }

    return (
        <Wrap>
            <BasicText fontSize={18} align='center'>앨범 선택</BasicText>
            <InnerWrap>
                {list.length === 0 ?
                    <Center style={{height:'150px',width:'100%'}}>앨범이 없습니다.</Center> :
                    list.map((data:any,index:any)=>{
                        return(
                            <UploadTestAlbumItem onClick={()=>setAlbum(data)} key={`upTest-album-${index}`} data={data} album={album}/>
                        )
                    })
                }
            </InnerWrap>
            <BtnWrap>
                <BtnItem onClick={()=>setOpen(true)}>앨범 생성</BtnItem>
            </BtnWrap>
            <OpenUploadTextAlbumPage user={user} open={open} setOpen={setOpen} setReload={setReload} />
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
`;
const InnerWrap = styled(Row)`
    margin-top: 10px;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    height: 460px;
    overflow-y: auto;
`;

const BtnWrap = styled(Center)``;
const BtnItem = styled(Center)`
    border: 1px solid #fff;
    border-radius: 4px;
    width: 120px;
    height: 40px;
    cursor: pointer;
`;